import React, { useState, useEffect, useContext } from "react";
import "./callBackPage.css"
import { useNavigate } from "react-router-dom";
import Successfull_Modal from "../../../Common_modal/Successfull_Modal/Successfull_Modal";
import { postData } from "../../../../utils/api";
import { Context } from "../../../../utils/context";
import Successfull_Lottie from "../../../Animation/Successfull_Lottie/Successfull_Lottie";
import Faild_Lottie from "../../../Animation/Faild_Lottie/Faild_Lottie";

const PaymentCallbackPage = () => {
    const [modalShow, setModalShow] = useState(false);
    const navigate = useNavigate();
    const { triggerCartUpdate } = useContext(Context);
    const [success, setSuccess] = useState(true);

    const myAsyncFunction = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token");

        if (token) {
            try {
                const decodedToken = JSON.parse(atob(token.split(".")[1]));

                console.log("decodedToken", decodedToken);

                const { order_id, user_id, transactionId, merchantId } = decodedToken;

                const data = {
                    order_id: order_id,
                    user_id: user_id,
                    transactionId: transactionId,
                    merchantId: merchantId
                };

                const res = await postData("/payment/callback", data);
                console.log("res", res?.data);

                if (res?.success) {
                    setSuccess(true);
                    triggerCartUpdate();
                    setTimeout(() => {
                        navigate("/my-account");
                    }, 3000);
                } else {
                    setSuccess(false);
                    console.log("Payment Faild");
                }

            } catch (error) {
                console.error("Failed to decode token:", error);
            }
        }
    };

    useEffect(() => {
        myAsyncFunction();
    }, [navigate]);

    return (
        <>
            <section className="checkout-process-step-form Product_Cart callBackPage">
                <div className="container" >
                    {/* <h2>Payment Status</h2>
                    <Successfull_Modal
                        message="Your order has been placed successfully"
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                    /> */}

                    <div className="success-lottie-text-holder">
                        <div>

                            <div className="lottie-holder">
                                {" "}
                                {success ? <Successfull_Lottie /> : <Faild_Lottie />}

                            </div>
                            {success ?
                                <p>Your order has been placed successfully</p>
                                :
                                <p>Oops , something went wrong</p>
                            }
                        </div>

                    </div>

                </div>
            </section>
        </>
    );
};

export default PaymentCallbackPage;
